import { ref, computed } from '@vue/composition-api'

// We haven't added icon's computed property because it makes this mixin coupled with UI
export const _ = null

export const togglePasswordFunc = () => {
  const passwordFieldType = ref('password')

  const togglePasswordVisibility = () => {
    passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
  }

  const passwordToggleIcon = computed(() => (passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))

  return {
    passwordFieldType,
    passwordToggleIcon,
    togglePasswordVisibility,
  }
}
